import { TOGGLE_VIEWMODE } from "../types";

const viewModeReducer = (state = false, { type, payload }) => {
  switch (type) {
    case TOGGLE_VIEWMODE:
      return payload;
    default:
      return state;
  }
};

export default viewModeReducer;
