import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import styled from 'styled-components'
import { resetCompaniesList } from "store/actions";
import { listAllCompanies } from "store/actions";
import CustomTable from "components/CustomTable/CustomTable";

const Title = styled.div`
    margin-top: 70px;
    text-align: center;

    @media screen and (max-width: 800px) {
        margin-top: 50px;
    }
`;

function CmpList() {
    const dispatch = useDispatch();

    const { companiesListData } = useSelector(({ user: { companiesList } }) => ({
        companiesListData: companiesList,
    }))

    useEffect(() => {
        dispatch(resetCompaniesList());
        dispatch(listAllCompanies({ countryExchange: "usa_dj" }));
    }, []);

    return (
        <>
            <div>
                <Grid container justify="center" spacing={3}>
                    <Grid item lg={12} xs={10}>
                        <Title>
                            <h4>{companiesListData[0]?.date}</h4>
                        </Title>
                    </Grid>

                    {companiesListData?.length ? (
                        <>
                            <CustomTable
                                columns={[
                                    {
                                        title: "Company name",
                                        render: (rowData) => (
                                            <>
                                                {rowData?.company_name}{" "}
                                                {`(${rowData?.scrip_name})`}
                                            </>
                                        ),
                                    },
                                    { title: "Cmp", field: "cmp" },
                                    { title: "Target", field: "target" },
                                    {
                                        title: "signal",
                                        render: (rowData) => (
                                            <b
                                                style={{
                                                    color:
                                                        rowData.signal === "BUY" ? "#25cc00" :
                                                            rowData.signal === "SELL" ? "red" : "#fff",
                                                }}
                                            >
                                                {rowData?.signal}
                                            </b>
                                        ),
                                    },
                                    { title: "lots available", field: "lots_available" },
                                ]}
                                data={companiesListData}
                            />
                        </>
                    ) : (
                        <h3>No data found</h3>
                    )}
                </Grid>
            </div>
        </>
    );
}

export default CmpList