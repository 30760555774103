export const ADMIN_LOGIN = `query ($email: String!, $password: String!){
  trader_admin_login(email: $email, password: $password){
    message
    token
  }
}`;

export const FETCH_ALL_USERS = `query{
  list_all_users{
    message
    data{
      _id
      email
      total_coins
    }
    hasMore
  }
}`;

export const FETCH_ALL_COMPANIES = `query ($countryExchange: String!){
  list_all_companies(country_exchange: $countryExchange){
    message
    all_companies{
      scrip_name
      company_name
      cmp
      target
      signal
      date
      lots_available
    }
  }
}`;