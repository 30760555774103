import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { resetAllUsers, listAllUsers } from "store/actions";
import CustomTable from "components/CustomTable/CustomTable";

const Title = styled.div`
    margin-top: 70px;
    text-align: center;

    @media screen and (max-width: 800px) {
        margin-top: 50px;
    }
`;

function Users() {
    const dispatch = useDispatch();

    const { usersListData } = useSelector(({ user: { usersList } }) => ({
        usersListData: usersList,
    }));

    useEffect(() => {
        dispatch(resetAllUsers());
        dispatch(listAllUsers());
    }, []);

    return (
        <>
            <div>
                <Grid container justify="center" spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Title>
                            <h4>{"Users"}</h4>
                        </Title>
                    </Grid>

                    {usersListData?.length > 0 ? (
                        <CustomTable
                            columns={[
                                { title: "email", field: "email" },
                                {
                                    title: "total coins",
                                    field: "total_coins",
                                },
                            ]}
                            data={usersListData}
                        />
                    ) : (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <h3>No data found</h3>
                        </div>
                    )}
                </Grid>
            </div>
        </>
    );
}

export default Users;
