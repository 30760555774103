import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import AdminLogin from "./adminLogin";

function Login() {

    return (
        <>
            <Grid item>
                <Card  style={{borderRadius:"20px", backgroundColor:"#101427",boxShadow:"0 3px 70px #000000"}}>
                    <div style={{textAlign:"center",marginTop:"20px",fontSize:"18px"}}>
                        <p>Sign In</p>
                    </div>
                    <CardContent style={{padding:"30px 50px 20px"}}>
                        <AdminLogin/>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default Login;
