import React, { useState } from "react";
import "./admin.css";
import styled from "styled-components";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress, Grid, Modal } from "@material-ui/core";
import { adminRoutes } from "routes.js";
import bgImg from "./../assets/bg/bgimg1.jpg";
import Button from "components/StyledComponents/Button";
import { logoutUser } from "store/actions";
import { history } from "utils";
import { isLoggedIn } from "utils";

const Outer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url(${bgImg});
`;

const Inner = styled.div`
    padding: 20px 30px 100px;
    padding-left: 130px;
    min-height: 100vh;
    width: 100%;
    color: #cccccc;
    background-color: rgb(16, 20, 39, 0.6);
    backdrop-filter: blur(55px);
    overflow-x: scroll;

    @media screen and (max-width: 990px) {
        padding: 30px 30px 100px;
    }
`;

const Icon = styled.div`
    width: 100%;
    b {
        font-size: 17px;
        padding: 3px 10px;
        border-radius: 50px;
        background-color: rgb(255, 255, 255, 0.1);
    }
    #back {
        font-size: 20px;
        margin-right: 20px;
        cursor: pointer;
    }
    #coin {
        margin-right: 10px;
        font-size: 20px;
        color: #ffd700;
    }
    #logout {
        cursor: pointer;
        font-size: 20px;
        float: right;
        &:hover {
            color: white;
        }
    }
`;

const ModalBox = styled.div`
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #101427;
    color: #cccccc;
    border-radius: 20px;
    border: 1px solid;
    padding: 30px 20px;
    text-align: center;
    box-shadow: 0 3px 70px #000000;
    h3 {
        margin-bottom: 30px;
    }
    h4 {
        margin-top: -10px;
    }

    @media screen and (max-width: 500px) {
        width: 300px;
    }
`;

const Menu = styled.div`
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    position: fixed;

    padding: 10px;
    background-color: rgb(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);

    @media screen and (min-width: 992px) {
        height: 100%;
        width: 100px;
        z-index: 1;
        left: 0;
        padding-top: 20px;
    }

    i {
        font-size: 20px;
        cursor: pointer;
    }
`;

const MenuLabel = styled.div`
    font-size: 12px;
    cursor: pointer;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (min-width: 992px) {
        margin-bottom: 10px;
        font-size: 13px;
    }
`;

const Admin = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [modal, setModal] = useState(false);
    const [backButton, setBackButton] = useState(false);
    const pathname = window.location.pathname;

    const { isAuthenticated, loading } = useSelector(
        ({
            auth: { token },
            loading,
        }) => ({
            isAuthenticated: isLoggedIn(token),
            loading,
        })
    );

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // mainContent.current.scrollTop = 0;
        if (
            pathname === "/app/users" ||
            pathname === "/app/predictions" ||
            pathname === "/app/results"
        ) {
            setBackButton(false);
        } else {
            setBackButton(true);
        }
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/app" && isAuthenticated) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return <Redirect to={"/auth/listings"} key={key} />;
            }
        });
    };

    const getTabs = (routes) => {
        return routes.map((tab, index) => {
            if (tab.visibility) {
                return (
                    <Grid item lg={12} xs={12} key={index}>
                        <div
                            onClick={() => history.push(`/app${tab.path}`)}
                            style={{
                                textAlign: "center",
                                color:
                                    `/app${tab.path}` === pathname
                                        ? "#2dbc9b"
                                        : "#cccccc",
                            }}
                        >
                            <i className={tab.icon} />
                            <MenuLabel> {tab.name} </MenuLabel>
                        </div>
                    </Grid>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Outer>
                <Inner>
                    <Icon>
                        {backButton ? (
                            <i
                                className="fas fa-arrow-left"
                                id="back"
                                onClick={() => history.goBack()}
                            />
                        ) : null}
                        {/* <b>
                            <i className="fa-solid fa-coins" id="coin" />
                            {total_coins ? total_coins : 0}
                        </b> */}
                        <i
                            className="fa fa-sign-out"
                            id="logout"
                            onClick={() => setModal(true)}
                        />
                    </Icon>

                    <Switch>
                        {getRoutes(adminRoutes)}
                        <Redirect from="*" to="/auth/listings" />
                    </Switch>
                </Inner>
            </Outer>

            <Menu>
                <Grid container spacing={2}>
                    {getTabs(adminRoutes)}
                </Grid>
            </Menu>

            <Modal open={modal} onClose={() => setModal(false)}>
                <ModalBox>
                    <h3>Do you really want to logout?</h3>
                    <Button
                        style={{
                            color: "white",
                            backgroundColor: "#616A94",
                        }}
                        onClick={() => setModal(false)}
                    >
                        Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={() => dispatch(logoutUser())}>
                        Logout
                    </Button>
                </ModalBox>
            </Modal>
        </>
    );
};

export default Admin;
