export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_VIEWMODE = "TOGGLE_VIEWMODE";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LIST_COMPANIES_LIST = "LIST_COMPANIES_LIST";
export const RESET_COMPANIES_LIST = "RESET_COMPANIES_LIST";

export const LIST_ALL_USERS = "LIST_ALL_USERS";
export const RESET_ALL_USERS = "RESET_ALL_USERS";

export const SHOW_TOTAL_COINS = "SHOW_TOTAL_COINS";
export const RESET_TOTAL_COINS = "RESET_TOTAL_COINS";

export const LIST_SIGNAL_RESULT_HISTORY = "LIST_SIGNAL_RESULT_HISTORY";
export const RESET_SIGNAL_RESULT_HISTORY = "RESET_SIGNAL_RESULT_HISTORY";