import { post } from "services";
import { FETCH_ALL_USERS, FETCH_ALL_COMPANIES } from "graphql";
import { TOGGLE_LOADING, LIST_ALL_USERS, RESET_ALL_USERS, LIST_COMPANIES_LIST } from "store/types";
import { RESET_COMPANIES_LIST } from "store/types";

export const listAllUsers = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_ALL_USERS
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_users: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_ALL_USERS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllUsers = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_USERS });
    };
};

export const listAllCompanies = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_ALL_COMPANIES,
            variables: requestData,
        });
        
        if (apiResponse.data.customStatus) {
            let {
                list_all_companies: { all_companies },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_COMPANIES_LIST,
                payload: all_companies,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetCompaniesList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_COMPANIES_LIST });
    };
};
