import { ADMIN_LOGIN } from "graphql";
import { unAuthorizedPost } from "services";
import { history } from "utils";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING } from "../types";

export const adminLogin = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: ADMIN_LOGIN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                trader_admin_login: { token },
            } = apiResponse.data.data;

            dispatch({
                type: LOGIN_SUCCESS,
                payload: token,
            });
            history.push("/app/users")
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};


export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};
