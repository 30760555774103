import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Grid, Modal } from "@material-ui/core";

import { authRoutes } from "routes.js";
import componentStyles from "assets/theme/layouts/auth.js";
import styled from "styled-components";
import bgImg from "./../assets/bg/bgimg1.jpg";
import { useSelector } from "react-redux";
import { history } from "utils";
import Button from "components/StyledComponents/Button";
import Login from "views/auth";

const useStyles = makeStyles(componentStyles);

const Outer = styled.div`
    width: 100%; 
    height: 100vh; 
    background-image: url(${bgImg});
`;

const Inner = styled.div`
    padding: 50px 30px 100px;
    min-height: 100vh; 
    width: 100%;
    color: #cccccc;
    background-color: rgb(16, 20, 39, 0.6); 
    backdrop-filter: blur(55px);
    overflow-x: scroll;
`;

const Nav = styled.div`
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    width: 100%;
    padding: 10px 30px;
    overflow: hidden;
    background-color: rgb(16, 20, 39, 0.2); 
`;

const MenuLabel = styled.div`
    font-size: 14px;
    margin-right: 20px;
    font-weight: bold;
    display: flex;
    cursor: pointer;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const Loginbox = styled.div`
  position : absolute;
  top : 50%;
  left : 50%;
  transform: translate(-50%, -50%);
  width: 430px;

  @media screen and (max-width: 500px) {
      width : 350px;
  }
`;

const Auth = () => {
    const classes = useStyles();
    // const mainContent = React.useRef(null);
    const location = useLocation();
    const [loginModal, setLoginModal] = useState(false);
    const pathname = window.location.pathname;


    const { loading } = useSelector(({ loading }) => ({
        loading,
    }));

    React.useEffect(() => {
        document.body.classList.add(classes.bgDefault);
        return () => {
            document.body.classList.remove(classes.bgDefault);
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return null;
            }
        });
    };

    const getTabs = (routes) => {
        return routes.map((tab, index) => {
            if (tab.visibility) {
                return (
                    <div key={index}
                        onClick={() => history.push(`/auth${tab.path}`)}
                        style={{
                            color:
                                `/auth${tab.path}` === pathname
                                    ? "#2dbc9b"
                                    : "#cccccc",
                        }}
                    >
                        {/* <i className={tab.icon} /> */}
                        <MenuLabel> {tab.name} </MenuLabel>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="primary" />
            </Backdrop>


            <Outer>
                <Inner>
                    <Switch>
                        {getRoutes(authRoutes)}
                        <Redirect from="*" to="/auth/listings" />
                    </Switch>
                </Inner>
            </Outer>

            <Nav>
                {getTabs(authRoutes)}
                <Button 
                onClick={() => setLoginModal(true)}
                style={{ color: "white", backgroundColor: "#616A94", padding: "8px 20px" }}>
                    Sign in
                </Button>
            </Nav>

            <Modal 
          open={loginModal}
          onClose={() => setLoginModal(false)}>
              <Loginbox>
                    <Login setLoginModal={ setLoginModal }/>
              </Loginbox>
        </Modal>
        </>
    );
};

export default Auth;
