import {
    LIST_ALL_USERS,
    LIST_COMPANIES_LIST,
    RESET_ALL_USERS,
    RESET_COMPANIES_LIST,
    // RESET_TOTAL_COINS, 
    // SHOW_TOTAL_COINS, 
    // LIST_SIGNAL_RESULT_HISTORY, 
    // RESET_SIGNAL_RESULT_HISTORY 
} from "../types";

const INITIAL_STATE = {
    companiesList: [],
    usersList: [],
    // predictionHistory: [],
    // resultHistory: [],
    // totalCoins: {},
};

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case LIST_COMPANIES_LIST:
            return {
                ...state,
                companiesList: payload,
            };

        case RESET_COMPANIES_LIST:
            return {
                ...state,
                companiesList: INITIAL_STATE.companiesList,
            };

        case LIST_ALL_USERS:
            return {
                ...state,
                usersList: payload,
            };

        case RESET_ALL_USERS:
            return {
                ...state,
                usersList: INITIAL_STATE.usersList,
            };

        // case SHOW_TOTAL_COINS:
        //     return {
        //         ...state,
        //         totalCoins: payload,
        //     };

        // case LIST_SIGNAL_RESULT_HISTORY:
        //     return {
        //         ...state,
        //         resultHistory: payload,
        //     };

        // case RESET_SIGNAL_RESULT_HISTORY:
        //     return {
        //         ...state,
        //         resultHistory: INITIAL_STATE.resultHistory,
        //     };

        // case RESET_TOTAL_COINS:
        //     return {
        //         ...state,
        //         totalCoins: INITIAL_STATE.totalCoins,
        //     };

        default:
            return state;
    }
};

export default userReducer;
