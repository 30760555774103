import { Grain } from "@material-ui/icons";

// import PredictionHistory from "views/predictions";
// import Results from "views/results";
// import ResultHistory from "views/home/resultHistory";
import CmpList from "views/home/cmpList";
import Users from "views/users";

var adminRoutes = [
    {
        path: "/users",
        name: "Users",
        icon: "fa fa-home",
        iconColor: "Primary",
        component: Users,
        layout: "/app",
        visibility: true,
    },
    // {
    //     path: "/predictions",
    //     name: "history",
    //     icon: "fa fa-history",
    //     iconColor: "Primary",
    //     component: PredictionHistory,
    //     layout: "/app",
    //     visibility: true,
    // },
    // {
    //     path: "/results",
    //     name: "results",
    //     icon: "fa-solid fa-rectangle-list",
    //     iconColor: "Primary",
    //     component: Results,
    //     layout: "/app",
    //     visibility: true,
    // },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/listings",
        name: "Listings",
        icon: Grain,
        iconColor: "Primary",
        component: CmpList,
        layout: "/auth",
        visibility: true,
    },
    // {
    //     path: "/history",
    //     name: "History",
    //     icon: Grain,
    //     iconColor: "Primary",
    //     component: ResultHistory,
    //     layout: "/auth",
    //     visibility: true,
    // },
];
export { adminRoutes, authRoutes };
